
.certificate-list-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;

    .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #F1F5FF;
        height: 40px;
        padding-bottom: 10px;

        .genera-breadcrumb {
            display: flex;
            align-items: center;

            &:before {
                content: unset;
            }
        }
    }

    .middle-wrap {
        padding: 15px 0;
        display: flex;
        justify-content: space-between;

        .question-class {
            display: flex;

            .question-item {
                display: flex;
                align-items: center;

                &:last-child {
                    padding-left: 48px;
                }

                .w300-cascader {
                    width: 300px;
                }

                .W200-select {
                    width: 200px;
                }

                .text {
                    padding-right: 10px;
                }
            }
        }
    }
}
